import type { Me } from "~/view-models/me.entity";

export const meClient = {
  getMe: async (): Promise<Me> => {
    const res = await fetch("/api/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await res.json();
  },
  postMe: async (firebaseToken: string): Promise<Me> => {
    const res = await fetch("/api/me", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ firebaseToken }),
    });
    return await res.json();
  },
};
