import { atom, useAtom } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import { useEffect } from "react";
import { meClient } from "~/routes/api.me/client";
import { type Me, convertMeModelToEntity } from "~/view-models/me.entity";
import { checkFirebaseTokenExp, currentAuthUserAtom } from "./useAuth";

type CurrentUserAtom = Me | null;

const currentUserAtom = atom<CurrentUserAtom>(null);

export function CurrentUserProvider({
  children,
  initCurrentUser,
}: {
  children: React.ReactNode;
  initCurrentUser: Me | null;
}) {
  useHydrateAtoms([[currentUserAtom, initCurrentUser]]);
  const [currentUser, setCurrentUser] = useAtom(currentUserAtom);
  const [firebaseUser] = useAtom(currentAuthUserAtom);

  useEffect(() => {
    if (!firebaseUser) return;
    (async () => {
      const shouldFetch = !currentUser || (await checkFirebaseTokenExp(firebaseUser));
      if (shouldFetch) {
        const firebaseToken = await firebaseUser.getIdToken(true);
        const user = await meClient.postMe(firebaseToken);
        setCurrentUser(user);
      }
    })();
  }, [firebaseUser, currentUser, setCurrentUser]);

  return children;
}

export function useCurrentUser() {
  const [currentUser] = useAtom(currentUserAtom);

  // if (!currentUser) {
  //   throw new Error("This hook should not be called when not logged in.");
  // }

  return {
    currentUser: currentUser && convertMeModelToEntity(currentUser),
  };
}
