import type { SerializeFrom } from "@remix-run/node";
import dayjs from "dayjs";
import * as v from "valibot";
import type { CurrentUserModel } from "../../../../packages/domain/src/models/user/current-user";
import { vParse } from "../libs/valibot";

// use SerializeFrom since JsonifyObject type can't be matched with plane object
export type Me = SerializeFrom<CurrentUserModel>;

const vMeViewEntitySchema = v.object({
  // @ts-ignore @see it can work, but it has type error https://day.js.org/docs/en/query/is-a-dayjs
  createdAt: v.instance(dayjs),
  // @ts-ignore
  updatedAt: v.instance(dayjs),
});

export interface MeViewEntity extends v.InferOutput<typeof vMeViewEntitySchema> {}

// 後でEntity化する
// この辺いい感じにする
export function convertMeModelToEntity(model: SerializeFrom<CurrentUserModel>) {
  const today = dayjs();
  const oneWeekAgo = today.subtract(7, "day");

  return {
    ...model,
    ...vParse(vMeViewEntitySchema, {
      createdAt: dayjs(model.createdAt),
      updatedAt: dayjs(model.updatedAt),
    }),
    organization: model.organization
      ? {
          ...model.organization,
          createdAt: dayjs(model.organization.createdAt),
          updatedAt: dayjs(model.organization.updatedAt),
          isNew: dayjs(model.organization.createdAt).isAfter(oneWeekAgo),
        }
      : undefined,
  };
}
